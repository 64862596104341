import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faHeartbeat,
  faUserMd,
  faFileMedical,
  faTasks,
} from '@fortawesome/free-solid-svg-icons';
import {
  ChakraProvider,
  extendTheme,
  Flex,
  Box,
  Container,
  SimpleGrid,
  Stack,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';
import HeaderButtons from '../components/HeaderButtons';

const ListHeader = ({ children }) => {
  return (
    <Text fontWeight={'500'} fontSize={'lg'} mb={2}>
      {children}
    </Text>
  );
};

const theme = extendTheme({
  components: {
    Menu: {
      baseStyle: {
        list: {
          bg: 'white',
          boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
          borderRadius: '8px',
          padding: '10px',
          minWidth: '400px',
          zIndex: 1100, // Add this to ensure menu appears above header
        },
        item: {
          fontSize: '18px',
          fontWeight: 'bold',
          _hover: { bg: '#f0f0f0' },
        },
      },
    },
    Button: {
      baseStyle: {
        fontWeight: 'bold',
        borderRadius: '5px',
      },
      variants: {
        ghost: {
          color: '#0058A3',
          _hover: { bg: 'transparent', color: '#004080' },
        },
      },
    },
  },
});

const Homepage = () => {
  const navigate = useNavigate();
  const [showPopup, setShowPopup] = useState(false);
  const popupRef = useRef(null); // Crear un ref para el pop-up

  const handleLoginFertilidad = () => {
    navigate('/login/fertilidad'); // Redirige a la página de inicio de sesión específica para Fertilidad
  };

  const handleLoginCardiologia = () => {
    navigate('/login/cardiologia'); // Redirige a la página de inicio de sesión específica para Cardiología
  };

  const handleLoginGinecologia = () => {
    navigate('/login/ginecologia'); // Redirige a la página de inicio de sesión específica para Ginecología
  };

  const handleRegister = () => {
    navigate('/signup'); // Redirige a la página de registro
  };

  // useEffect para manejar clics fuera del pop-up
  useEffect(() => {
    const handleClickOutside = event => {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        setShowPopup(false); // Cierra el pop-up si se hace clic fuera de él
      }
    };

    // Añadir evento de clic cuando el pop-up esté visible
    if (showPopup) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [showPopup]);

  return (
    <ChakraProvider theme={theme}>
      <div style={styles.container}>
        {/* Encabezado */}
        <header style={{ ...styles.header, padding: '1rem 2rem' }}>
          <img
            src={`${process.env.PUBLIC_URL}/logo_crop.png`}
            alt="Medify AI Logo"
            style={{ ...styles.logo, width: '150px' }}
          />
          {/* <Flex as="nav" align="center" gap="1rem" style={styles.nav}>
            <Link href="#inspiration" style={styles.navButton}>
              Inspiration
            </Link>
            <Link href="#find-work" style={styles.navButton}>
              Find Work
            </Link>
            <Link href="#learn-design" style={styles.navButton}>
              Learn Design
            </Link>
            <Link href="#hire-designers" style={styles.navButton}>
              Hire Designers
            </Link>
          </Flex> */}
          <Flex align="center" gap="1rem">
            <HeaderButtons
              onLoginFertilidad={handleLoginFertilidad}
              onLoginCardiologia={handleLoginCardiologia}
              onLoginGinecologia={handleLoginGinecologia}
            />
          </Flex>
          {/* <Button onClick={handleRegister} style={styles.signUpButton}>
              Sign Up
            </Button> */}
        </header>

        {/* Sección de Servicios */}
        <section style={styles.servicesSection}>
          <h1 style={styles.title}>Nuestros Servicios</h1>
          <p style={styles.description}>
            Descubre cómo Medify AI puede mejorar tu práctica médica con
            nuestras soluciones de inteligencia artificial.
          </p>
          <div style={styles.servicesIcons}>
            <div style={styles.iconBox}>
              <img
                src={`${process.env.PUBLIC_URL}/fertilityicon.png`}
                alt="Fertilidad"
                style={styles.iconImage}
              />
              <p style={styles.iconLabel}>Fertilidad</p>
            </div>
            <div style={styles.iconBox}>
              <img
                src={`${process.env.PUBLIC_URL}/cardiologyicon.png`}
                alt="Cardiología"
                style={styles.iconImage}
              />
              <p style={styles.iconLabel}>Cardiología</p>
            </div>
            <div style={styles.iconBox}>
              <img
                src={`${process.env.PUBLIC_URL}/gynecologyicon.png`}
                alt="Ginecología"
                style={styles.iconImage}
              />
              <p style={styles.iconLabel}>Ginecología</p>
            </div>
          </div>
        </section>

        {/* Sección de Beneficios */}
        <section style={styles.benefitsSection}>
          <h1 style={styles.title}>
            Beneficios de Integrar AI en tu Consultorio
          </h1>
          <p style={styles.description}>
            La inteligencia artificial puede transformar tu práctica médica de
            muchas maneras. Aquí te mostramos algunos de los beneficios.
          </p>
          <div style={styles.benefitsGrid}>
            <div style={styles.benefitCard}>
              <div style={styles.iconContainer}>
                <FontAwesomeIcon icon={faTasks} style={styles.icon} />
              </div>
              <h2 style={styles.benefitTitle}>
                Ahorro de Tiempo en Tareas Administrativas
              </h2>
              <p style={styles.benefitDescription}>
                Automatiza la generación de historias clínicas y resúmenes
                permitiéndote ahorrar hasta un 30% de tu tiempo diario.
              </p>
            </div>
            <div style={styles.benefitCard}>
              <div style={styles.iconContainer}>
                <FontAwesomeIcon icon={faUserMd} style={styles.icon} />
              </div>
              <h2 style={styles.benefitTitle}>
                Mejora la relación médico - paciente
              </h2>
              <p style={styles.benefitDescription}>
                Al automatizar tareas repetitivas, podrás invertir tu tiempo en
                lo que más importa: el cuidado del paciente.
              </p>
            </div>
            <div style={styles.benefitCard}>
              <div style={styles.iconContainer}>
                <FontAwesomeIcon icon={faFileMedical} style={styles.icon} />
              </div>
              <h2 style={styles.benefitTitle}>
                Historias Clínicas más claras y estandarizadas
              </h2>
              <p style={styles.benefitDescription}>
                Generá documentación clara y robusta que te permita realizar un
                mejor seguimiento de pacientes, cometer menor errores y obtener
                mayor respaldo legal.
              </p>
            </div>
            <div style={styles.benefitCard}>
              <div style={styles.iconContainer}>
                <FontAwesomeIcon icon={faHeartbeat} style={styles.icon} />
              </div>
              <h2 style={styles.benefitTitle}>Medicina Personalizada</h2>
              <p style={styles.benefitDescription}>
                Ofrece tratamientos personalizados basados en datos precisos.
              </p>
            </div>
          </div>
        </section>

        <section id="sobre-medify" style={styles.summarySection}>
          <h1 style={styles.title}>El Burnout Médico: Un Problema Crítico</h1>
          <p style={styles.description}>
            El burnout médico ha alcanzado niveles críticos a nivel global,
            afectando a la gran mayoría de los profesionales (desde 30% al 50%){' '}
            <a
              href="https://www.cdc.gov/vitalsigns/health-worker-mental-health/index.html#:~:text=The%20study%20showed%20how%20symptoms,compared%20to%2032%25%20in%202018."
              target="_blank"
              rel="noopener noreferrer"
            >
              (1)
            </a>
            .
          </p>
          <p style={styles.description}>
            Este agotamiento está relacionado con una brecha tecnológica en la
            práctica médica, donde los profesionales dedican entre el 30% y 55%
            de su tiempo a tareas administrativas como la creación y gestión de
            registros electrónicos{' '}
            <a
              href="https://www.medscape.com/slideshow/2020-lifestyle-burnout-6012460#5"
              target="_blank"
              rel="noopener noreferrer"
            >
              (2)
            </a>
            . y también está asociado a la constante exposición de los
            profesionales a decisiones complejas de lidiar con la salud de los
            pacientes.
          </p>
          <h2 style={styles.subtitle}>Cómo Medify AI Cierra la Brecha</h2>
          <p style={styles.description}>
            La inteligencia artificial (IA) se presenta como una solución
            revolucionaria para una práctica donde la tecnología aún tiene mucho
            por avanzar{' '}
            <a
              href="https://catalyst.nejm.org/doi/full/10.1056/CAT.23.0404?query=CON&cid=DM2321351_Catalyst_Non_Subscriber&bid=2072618441#sec-2"
              target="_blank"
              rel="noopener noreferrer"
            >
              (3).
            </a>
          </p>
          <p style={styles.description}>
            En Medify AI, nos especializamos en cerrar este gap tecnológico,
            brindando soluciones basadas en inteligencia artificial que
            automatizan las tareas administrativas y permiten una atención más
            personalizada y eficiente. Al optimizar los flujos de trabajo,
            ayudamos a los profesionales a recuperar el tiempo que antes
            dedicaban a tareas sin valor agregado, para que puedan enfocarse en
            lo que realmente importa: mejorar la experiencia del paciente y
            ofrecer un cuidado de calidad.
          </p>
        </section>

        <section style={styles.clientsSection}>
          <h1 style={styles.title}>Clientes que confían en nosotros</h1>
          <p style={styles.description}>
            Los profesionales que usan Medify AI pertenecen a las siguientes
            instituciones:
          </p>
          <div style={styles.clientsGridContainer}>
            <div style={styles.clientsGridRow}>
              <img
                src={`${process.env.PUBLIC_URL}/1.png`}
                alt="In Vitro Buenos Aires"
                style={styles.clientImage}
              />
              <img
                src={`${process.env.PUBLIC_URL}/2.png`}
                alt="Pregna"
                style={styles.clientImage}
              />
              <img
                src={`${process.env.PUBLIC_URL}/3.png`}
                alt="Saresa"
                style={styles.clientImage}
              />
              <img
                src={`${process.env.PUBLIC_URL}/4.png`}
                alt="Clinica de Reproducción Humana"
                style={styles.clientImage}
              />

              <div style={styles.clientsGridRow}>
                <img
                  src={`${process.env.PUBLIC_URL}/5.png`}
                  alt="Nascentis"
                  style={styles.clientImage}
                />
                <img
                  src={`${process.env.PUBLIC_URL}/6.png`}
                  alt="Fertilia"
                  style={styles.clientImage}
                />
                <img
                  src={`${process.env.PUBLIC_URL}/7.png`}
                  alt="Ameris"
                  style={styles.clientImage}
                />
              </div>
              <img
                src={`${process.env.PUBLIC_URL}/8.png`}
                alt="Fertilis"
                style={styles.clientImage}
              />
            </div>
          </div>
        </section>

        {/* Footer */}
        <Box
          bg={useColorModeValue('gray.50', 'gray.900')}
          color={useColorModeValue('gray.700', 'gray.200')}
          width="100%"
        >
          <Container as={Stack} maxW={'6xl'} py={10}>
            <SimpleGrid
              templateColumns={{ sm: '1fr 1fr', md: '2fr 1fr 1fr 1fr 1fr' }}
              spacing={8}
            >
              <Stack spacing={6}>
                <Box>
                  <img
                    src={`${process.env.PUBLIC_URL}/logo.png`}
                    alt="Medify AI Logo"
                    style={{ height: '128px', width: 'auto' }}
                  />
                </Box>
                <Text fontSize={'sm'}>
                  © 2024 Medify AI. All rights reserved
                </Text>
              </Stack>
              {/* <Stack align={'flex-start'}> */}
              {
                /* <ListHeader>Product</ListHeader>
                <Box as="a" href={'#'}>
                  Overview
                </Box>
                <Box as="a" href={'#'}>
                  Features
                </Box>
                <Box as="a" href={'#'}>
                  Tutorials
                </Box>
                <Box as="a" href={'#'}>
                  Pricing
                </Box>
                <Box as="a" href={'#'}>
                  Releases
                </Box>
              </Stack>*/
                <Stack align={'flex-start'}>
                  <ListHeader>Company</ListHeader>
                  {/* <Box as="a" href={'#'}>
                    About
                  </Box>
                  <Box as="a" href={'#'}>
                    Press
                  </Box>
                  <Box as="a" href={'#'}>
                    Careers
                  </Box> */}
                  <Box
                    as="a"
                    href={`https://mail.google.com/mail/?view=cm&fs=1&to=${encodeURIComponent(
                      'consultas@medifyai-health.com'
                    )}&su=${encodeURIComponent('Consulta Medify')}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Contact
                  </Box>
                  {/* <Box as="a" href={'#'}>
                    Partners
                  </Box> */}
                </Stack>
                /*<Stack align={'flex-start'}>
                <ListHeader>Support</ListHeader>
                <Box as="a" href={'#'}>
                  Help Center
                </Box>
                <Box as="a" href={'#'}>
                  Terms of Service
                </Box>
                <Box as="a" href={'#'}>
                  Legal
                </Box>
                <Box as="a" href={'#'}>
                  Privacy Policy
                </Box>
                <Box as="a" href={'#'}>
                  Status
                </Box>
              </Stack> */
              }
              <Stack align={'flex-start'}>
                <ListHeader>Follow Us</ListHeader>
                {
                  /* <Box as="a" href={'#'}>
                  Facebook
                </Box>
                <Box as="a" href={'#'}>
                  Twitter
                </Box>*/
                  <Box as="a" href={'https://wa.me/5491141756398'}>
                    WhatsApp
                  </Box>

                  /*<Box as="a" href={'#'}>
                  Instagram
                </Box> */
                }
                <Box
                  as="a"
                  href={
                    'https://www.linkedin.com/company/medi-ai2/?viewAsMember=true'
                  }
                >
                  LinkedIn
                </Box>
              </Stack>
            </SimpleGrid>
          </Container>
        </Box>
      </div>
    </ChakraProvider>
  );
};

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '100px 20px 20px', // Increased top padding to account for fixed header
    backgroundColor: '#F7f7f7',
    fontFamily: '"Montserrat", "Poppins", sans-serif',
    minHeight: '100vh',
    boxSizing: 'border-box',
    position: 'relative', // Add this to create a new stacking context
  },
  header: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '1rem 2rem',
    backgroundColor: 'white',
    position: 'fixed',
    top: 0,
    left: 0,
    zIndex: 1000,
    boxShadow: '0 1px 3px rgba(0, 0, 0, 0.1)',
  },
  logo: {
    width: '120px',
    height: 'auto',
  },
  servicesSection: {
    textAlign: 'center',
    marginBottom: '60px',
    marginTop: '60px',
  },
  servicesButtons: {
    display: 'flex',
    justifyContent: 'center', // Centramos los botones en el contenedor
    gap: '20px', // Espacio entre los botones
    flexWrap: 'wrap', // Permite que los botones se envuelvan si es necesario
    width: '100%', // Asegura que el contenedor ocupe todo el ancho
  },
  primaryButton: {
    backgroundColor: '#0058A3',
    color: '#FFFFFF',
    padding: '15px 30px',
    fontSize: '20px',
    fontWeight: 'bold',
    borderRadius: '8px',
    border: 'none',
    cursor: 'pointer',
    transition: 'background-color 0.3s ease, transform 0.3s ease',
    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
    width: '250px', // Ancho fijo para todos los botones
    maxWidth: '300px', // Máximo ancho en pantallas más grandes
    minWidth: '150px', // Mínimo ancho para pantallas más pequeñas
    orderBottom: '0px solid transparent',
  },
  secondaryButton: {
    backgroundColor: '#00B3D9',
    color: '#FFFFFF',
    padding: '15px 30px',
    fontSize: '20px',
    fontWeight: 'bold',
    borderRadius: '8px',
    border: 'none',
    cursor: 'pointer',
    transition: 'background-color 0.3s ease, transform 0.3s ease',
    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
    width: '250px', // Ancho fijo para todos los botones
    maxWidth: '300px', // Máximo ancho en pantallas más grandes
    minWidth: '150px', // Mínimo ancho para pantallas más pequeñas
    orderBottom: '0px solid transparent',
  },
  tertiaryButton: {
    backgroundColor: '#FF69B4',
    color: '#FFFFFF',
    padding: '15px 30px',
    fontSize: '20px',
    fontWeight: 'bold',
    borderRadius: '8px',
    border: 'none',
    cursor: 'pointer',
    transition: 'background-color 0.3s ease, transform 0.3s ease',
    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
    width: '250px', // Ancho fijo para todos los botones
    maxWidth: '300px', // Máximo ancho en pantallas más grandes
    minWidth: '150px', // Mínimo ancho para pantallas más pequeñas
  },
  benefitsSection: {
    textAlign: 'center',
    flex: 1,
  },
  benefitsGrid: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fit, minmax(250px, 1fr))',
    gap: '20px',
    justifyContent: 'center',
  },
  benefitCard: {
    backgroundColor: '#FFFFFF',
    borderRadius: '8px',
    padding: '20px',
    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
    transition: 'transform 0.3s ease, box-shadow 0.3s ease',
    cursor: 'pointer',
    textAlign: 'center',
    orderBottom: '0px solid transparent',
  },
  iconContainer: {
    marginBottom: '15px',
    orderBottom: '0px solid transparent',
  },
  icon: {
    fontSize: '50px',
    color: '#0058A3',
  },
  benefitTitle: {
    fontSize: '20px',
    fontWeight: '600',
    color: '#2F2F2F',
    marginBottom: '10px',
  },
  benefitDescription: {
    fontSize: '16px',
    color: '#2F2F2F',
  },
  summarySection: {
    backgroundColor: '#F7f7f7',
    borderRadius: '8px',
    marginTop: '60px',
    width: '100%',
    maxWidth: '1300px', // Aseguramos que el ancho máximo sea similar al de las tarjetas de beneficios
    margin: '40px auto', // Centramos el bloque horizontalmente
    padding: '30px',
    orderBottom: '0px solid transparent',
  },
  title: {
    textAlign: 'center',
    fontSize: '32px',
    fontWeight: 'bold',
    color: '#2F2F2F',
    marginBottom: '20px',
    borderBottom: '0px solid transparent',
  },
  subtitle: {
    textAlign: 'center',
    fontSize: '28px',
    fontWeight: '600',
    color: '#2F2F2F',
    marginTop: '40px',
    marginBottom: '20px',
    borderBottom: '0px solid transparent',
  },
  description: {
    textAlign: 'center',
    fontSize: '18px',
    color: '#2F2F2F',
    lineHeight: '1.6',
    marginBottom: '20px',
    borderBottom: '0px solid transparent',
  },
  servicesIcons: {
    display: 'flex',
    justifyContent: 'center', // Centra los íconos horizontalmente
    gap: '50px', // Espaciado entre los íconos
    marginBottom: '10px', // Espacio inferior para separación de otras secciones
  },
  iconBox: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
    border: '4px solid #0058A3', // Borde alrededor del ícono y la etiqueta
    borderRadius: '12px', // Bordes redondeados para la caja
    padding: '20px', // Espaciado interno para que los íconos y el texto no toquen los bordes
    width: '200px', // Ancho fijo para las cajas
    height: '200px',
    marginTop: '50px',
    marginBottom: '20px', // Espaciado inferior para separar las cajas del resto del contenido
    backgroundColor: 'transparent', // Fondo transparente para que solo el borde sea visible
  },
  iconImage: {
    width: '80px', // Ajusta el tamaño del ícono según sea necesario
    height: '80px',
    marginBottom: '20px', // Espacio entre el ícono y la etiqueta
  },
  iconLabel: {
    fontSize: '20px',
    fontWeight: 'bold',
    color: '#2F2F2F', // Color del texto de la etiqueta
    textAlign: 'center',
  },
  link: {
    color: '#0058A3',
    textDecoration: 'none',
    fontWeight: 'bold',
  },
  // Add these to the styles object
  clientsSection: {
    textAlign: 'center',
    marginTop: '60px',
    marginBottom: '60px',
    width: '100%',
    maxWidth: '1300px',
    margin: '40px auto',
    padding: '30px',
  },
  clientsGridContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '40px',
    marginTop: '40px',
    padding: '20px',
  },
  clientsGridRow: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '60px',
    flexWrap: 'wrap',
  },
  clientImage: {
    maxWidth: '180px',
    height: 'auto',
    objectFit: 'contain',
    opacity: '0.7',
    // WebkitFilter: 'grayscale(100%)', // Add webkit prefix for better browser support
    cursor: 'pointer',
  },
  // Media queries para pantallas pequeñas
  '@media (max-width: 768px)': {
    header: {
      flexDirection: 'column',
    },
    logo: {
      width: '150px',
    },
    nav: {
      flexDirection: 'column',
      gap: '10px',
    },
    servicesButtons: {
      flexDirection: 'column',
      width: '100%',
    },
    primaryButton: {
      width: '100%',
    },
    benefitsGrid: {
      gridTemplateColumns: '1fr',
    },
    summarySection: {
      textAlign: 'center',
      backgroundColor: '#F9F9F9',
      borderRadius: '8px',
      marginTop: '60px',
      boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
      width: '100%',
      maxWidth: '1100px', // Mismo ancho máximo que las tarjetas de beneficios
      margin: '40px auto', // Centrado en la página
      padding: '30px',
    },
    title: {
      fontSize: '32px',
      fontWeight: 'bold',
      color: '#2F2F2F',
      marginBottom: '20px',
    },
    subtitle: {
      fontSize: '28px',
      fontWeight: '600',
      color: '#2F2F2F',
      marginTop: '40px',
      marginBottom: '20px',
    },
    description: {
      fontSize: '18px',
      color: '#2F2F2F',
      lineHeight: '1.6',
      marginBottom: '20px',
    },
    link: {
      color: '#0058A3',
      textDecoration: 'none',
      fontWeight: 'bold',
    },
    clientsGrid: {
      gridTemplateColumns: 'repeat(2, 1fr)',
      gap: '20px',
    },
    clientImage: {
      maxWidth: '140px',
    },
  },
};

export default Homepage;
