import React from 'react';
import {
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Portal,
  Flex,
  Text,
  Box,
} from '@chakra-ui/react';
import { LogIn } from 'lucide-react';

const HeaderButtons = ({
  onLoginFertilidad,
  onLoginCardiologia,
  onLoginGinecologia,
}) => {
  const menuItemStyles = {
    display: 'block',
    w: 'full',
    p: '8px',
    mb: '5px',
    bg: 'white',
    color: '#0058A3',
    border: '2px solid #0058A3',
    borderRadius: '5px',
    fontWeight: 'bold',
    textAlign: 'center',
    cursor: 'pointer',
    fontSize: '16px',
    transition: 'background-color 0.3s ease, border-color 0.3s ease',
    _hover: {
      bg: '#0058A3',
      color: 'white',
    },
    _last: {
      mb: 0,
    },
  };

  return (
    <Flex align="center">
      <Menu>
        <MenuButton
          as={Box}
          display="flex"
          alignItems="center"
          color="#0058A3"
          _hover={{ color: 'black' }}
          transition="colors 0.2s"
        >
          <Box as={LogIn} h="4" w="12" mr="2" transition="colors 0.2s" />
          <Text fontWeight="medium">Iniciar Sesión</Text>
        </MenuButton>
        <Portal>
          <MenuList
            bg="white"
            borderRadius="lg"
            boxShadow="lg"
            p="3"
            minW="300px"
            mt="2"
          >
            <MenuItem onClick={onLoginFertilidad} {...menuItemStyles}>
              Fertilidad
            </MenuItem>
            <MenuItem onClick={onLoginCardiologia} {...menuItemStyles}>
              Cardiología
            </MenuItem>
            <MenuItem onClick={onLoginGinecologia} {...menuItemStyles}>
              Ginecología
            </MenuItem>
          </MenuList>
        </Portal>
      </Menu>
    </Flex>
  );
};

export default HeaderButtons;
